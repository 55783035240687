import React from "react"
import Layout from "../components/Layout"

export default function NotFound() {
  return (
    <Layout isFrontpage={true} lang={"fi"}>
      <div className="background-container bg-cover bg-center bg-fixed z-0">
        <div className="slogan text-center sticky top-0 flex flex-col justify-center h-screen -mt-32">
          <div className="bg-white opacity-75 rounded-lg p-4 m-4 md:m-16 font-bold text-xl">
            <p>Hups! Sivua ei löytynyt.</p>
            <p>Oops! Page not found.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
